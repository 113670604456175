import { Controller } from "@hotwired/stimulus"
import RecentPostHistory from '../recent_post_history'

export default class extends Controller {
  static targets = [ "link" ]
  static values = {
    currentPostNumber: Number,
    numPosts: Number,
    baseUrl: String
  }
  static classes = [ "disabled", "enabled" ]

  connect() {
    this.prepareLink()
  }

  prepareLink() {
    this.generateRandomUrl()
    this.enableLink()
  }

  get randomPostId() {
    let randomNumber;
    let tries = 0

    const recentPostHistory = new RecentPostHistory(this.numPostsValue, this.currentPostNumberValue)
    const recentPostIdsSet = recentPostHistory.recentPostIdsSet

    do {
      randomNumber = Math.floor(Math.random() * this.numPostsValue) + 1;
      tries++;
    } while (recentPostIdsSet.has(randomNumber) || tries <= this.numPostsValue);

    return randomNumber
  }

  generateRandomUrl() {
    if (!this.hasNumPostsValue) { return }
    if (!this.hasBaseUrlValue) { return }
    
    this.link.href = `${this.baseUrlValue}${this.randomPostId.toString().padStart(4, '0')}`
  }

  enableLink() {
    this.link.classList.remove(...this.disabledClasses)
    this.link.classList.add(...this.enabledClasses)
  }

  get link() {
    if (this.hasLinkTarget) {
      return this.linkTarget
    }
    return this.element
  }
}