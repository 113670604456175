export default class RecentPostHistory {
  constructor(maxAmount, currentPostId, maxStorageTime = 600000) {
    this.maxAmount = maxAmount;
    this.currentPostId = currentPostId;
    this.maxStorageTime = maxStorageTime; // default 10 minutes
    this.storageKey = 'recentPostIds';
    this.timestampKey = 'recentPostsLastSavedTimestamp';
    this.initializeStorage();

    this.initializeTimer();
    this.initializeNumbers();
  }

  initializeStorage() {
    try {
      JSON.parse(sessionStorage.getItem(this.storageKey))
    } catch (e) {
      this.resetStorage()
    }
  }

  resetStorage() {
    sessionStorage.setItem(this.storageKey, JSON.stringify(this.defaultPostIds));
    sessionStorage.setItem(this.timestampKey, Date.now());
  }

  get defaultPostIds() {
    if (this.isPostIdValid(this.currentPostId)) {
      return [this.currentPostId]
    }
    return []
  }

  initializeTimer() {
    this.currentTime = Date.now();
    this.lastTime = parseInt(sessionStorage.getItem(this.timestampKey), 10);

    if (this.currentTime - this.lastTime > this.maxStorageTime) {
      this.resetStorage();
      this.initializeTimer();
    }
  }

  initializeNumbers(maxAmount) {
    this.recentPostIdsSet = new Set(JSON.parse(sessionStorage.getItem(this.storageKey)));
    
    if (this.isPostIdValid(this.currentPostId)) {
      this.recentPostIdsSet.add(this.currentPostId);
    }
    if (this.recentPostIdsSet.size >= this.maxAmount) {
      this.resetStorage();
      this.initializeNumbers();
    } else {
      this.updateStorage();
    }
  }

  updateStorage() {
    sessionStorage.setItem(this.storageKey, JSON.stringify(Array.from(this.recentPostIdsSet)));
    sessionStorage.setItem(this.timestampKey, Date.now());
  }

  isPostIdValid(number) {
    return number !== null && number > 0 && number <= this.maxAmount
  }
}
